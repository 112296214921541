import React from 'react';
import { Row, Col } from 'reactstrap';
var aHtml,bHtml;

class itemCarousel extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
        }     
    }
    render() {
        return(            
                aHtml={__html:this.props.tituloContenido},
                bHtml={__html:this.props.Contenido},                
                    <Row>
                        <Col xs='12'>
                            <Row>
                                <Col xl='6' lg='6' md='6' sm='12' xs='12' >
                                    <div className="contentTest">
                                        <div className="imgContentT"></div>
                                        <img src={this.props.Imagen} alt={this.props.Imagen} className="m-auto img-testimonial" />
                                    </div>
                                </Col>
                                <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                                    <div className="client-testimonial">
                                        <h3 className="m-auto" >{this.props.Cliente_testimonio}</h3>
                                        <h4 className="m-auto" dangerouslySetInnerHTML={aHtml!==undefined ? aHtml:""}></h4>
                                        <p className="m-auto"  dangerouslySetInnerHTML={bHtml!==undefined ? bHtml:""}></p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                
            

        )
    }
}

export default itemCarousel;