import React from 'react';
import './index.css';
import { Container, Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';
import logoBlack from '../../assets/images/oruga-black.png';
import logoWhite from '../../assets/images/oruga-white.png';
import inview from 'in-view';
var url = window.location.pathname;

class Opciones extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            items: [],
            currentLogo: logoWhite,
            categories:[],
            direction: null,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("scroll", this.scrollHandler);
    }

    componentDidMount() {
        document.addEventListener("scroll", this.handleScroll);
        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/menus/v1/menus/social',{headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                items: res.items
            });

            if(this.state.items.length) {
                var social = document.querySelectorAll(".menu-principal-link");
                social.forEach((el) => {
                    el.style.color = "white"
                });
                document.querySelector(".menu-social-container").style.color = "white";
                document.querySelector(".portfolio-link").style.color = "white";
                if(url === '/nosotros/' || url === '/nosotros') {
                    social.forEach((el) => {
                        el.style.color = "black"
                    });
                    document.querySelector(".menu-social-container").style.color = "black";
                    document.querySelector(".portfolio-link").style.color = "black";
                }
            }
        }.bind(this))
        .catch(err => console.error(err));

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/categories?exclude=1&order=desc',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
       }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                categories: res
            });
        }.bind(this))
        .catch(err => console.log(err));
    }

    handleClick = () => {
        let menuPrincipal = document.querySelector('#menu-principal-container');
        let hasShowClass = menuPrincipal.classList.contains('show-menu-principal');
        hasShowClass ? menuPrincipal.classList.remove('show-menu-principal') : menuPrincipal.classList.add('show-menu-principal');
        let menuItems = document.querySelectorAll('.menu-container .menu-principal .menu-principal-item a');
        menuItems.forEach((val, key) => {
            hasShowClass ? val.classList.add('unable-menu-items') : val.classList.remove('unable-menu-items');
        });
        let body = document.body;
        hasShowClass ? body.removeAttribute('style') : body.setAttribute('style', 'overflow:hidden');
    }

    handleScroll = () => {
        var burger             = document.querySelectorAll(".burger-item");
        var siguenos_container = document.querySelector(".menu-social-container");
        var portafolio         = document.querySelector(".portfolio-link");
        var menu               = document.getElementById("options");
        var social             = document.querySelectorAll(".menu-principal-link");

        var positionFeatured = document.getElementById("featured");
        var positionPrimeraSeccion = document.getElementById("primeraSeccion");
        var positionBlogBody = document.getElementById("blog-body");
        var positionSegundaSeccion = document.getElementById("segundaSeccion");
        var positionFeaturedAbout = document.getElementById("featured-about");
        var positionTeamWork = document.getElementById("teamWork");

        var videoPosition = document.getElementById("videoReel");
        var footerPosition = document.getElementById("foo");

        if(url === '/blog/' || url === '/blog') {
            var filter = document.getElementById("filter");

            if(!inview.is(positionFeatured)) {
                if(!this._isMounted) {
                    return
                }
                this.setState({currentLogo: logoBlack});
                burger.forEach((el) => {
                    el.style.background = "black";
                });
            } else if(inview.is(positionFeatured) && inview.is(positionBlogBody)) {
                if(!this._isMounted) {
                    return
                }
                filter.style.color = "black";
                siguenos_container.style.color = "black";
                social.forEach((el) => {
                    el.style.color = "black"
                });
            } else if(inview.is(positionFeatured) || inview.is(footerPosition)) {
                if(!this._isMounted) {
                    return
                }
                this.setState({currentLogo: logoWhite});
                burger.forEach((el) => {
                    el.style.background = "white";
                });
                filter.style.color = "white";
                siguenos_container.style.color = "white";
                social.forEach((el) => {
                    el.style.color = "white"
                });
            }
        } else if(url === '/nosotros/' || url === '/nosotros') {
            if(!inview.is(positionFeaturedAbout)) {
                if(!this._isMounted) {
                    return
                }
                this.setState({currentLogo: logoBlack});
                burger.forEach((el) => {
                    el.style.background = "black";
                });
                siguenos_container.style.color = "black";
                social.forEach((el) => {
                    el.style.color = "black"
                });
                portafolio.style.color = "black";
            } else {
                if(!this._isMounted) {
                    return
                }
                this.setState({currentLogo: logoWhite});
                burger.forEach((el) => {
                    el.style.background = "white";
                });
            }

            // white footer down
            if (inview.is(footerPosition)){
                social = document.querySelectorAll(".menu-principal-link");
                social.forEach((el) => {
                    el.style.color = "white"
                });
                menu.classList.toggle("black", false);
                siguenos_container.style.color = "white";
                portafolio.style.color = "white";

                if(!inview.is(positionTeamWork)) {
                    this.setState({
                        currentLogo: logoWhite
                    });
                    burger.forEach((el) => {
                        el.style.background = "white"
                    });
                }
            }
        } else {
            // black primera seccion
            if (inview.is(positionPrimeraSeccion)){
                if(!this._isMounted) {
                    return
                }
                social = document.querySelectorAll(".menu-principal-link");
                Object.keys(social).forEach((key) => {
                    social[key].style.color = "black"
                });
                menu.classList.toggle("black", true);
                siguenos_container.style.color = "black";
                portafolio.style.color = "black";

                if(!inview.is(positionFeatured)) {
                    if(!this._isMounted) {
                        return
                    }
                    Object.keys(burger).forEach((key)=>{
                        burger[key].style.background = "black"
                    });
                    this.setState({currentLogo: logoBlack});
                }
            }

            //white destacado up
            if(inview.is(positionFeatured)) {
                this.setState({
                    currentLogo: logoWhite
                });
                Object.keys(burger).forEach((key) => {
                    burger[key].style.background = "white"
                });

                if (!inview.is(positionPrimeraSeccion)){
                    
                    social = document.querySelectorAll(".menu-principal-link");
                    Object.keys(social).forEach((key) => {
                        social[key].style.color = "white"
                    });
                    menu.classList.toggle("black", false);
                    siguenos_container.style.color = "white";
                    portafolio.style.color = "white";
                }
            }

            // white video down
            if (inview.is(videoPosition)){
                social = document.querySelectorAll(".menu-principal-link");
                Object.keys(social).forEach((key) => {
                    social[key].style.color = "white"
                });
                menu.classList.toggle("black", false);
                siguenos_container.style.color = "white";
                portafolio.style.color = "white";

                if(!inview.is(positionPrimeraSeccion) && inview.is(videoPosition)) {
                    this.setState({
                        currentLogo: logoWhite
                    });
                    Object.keys(burger).forEach((key) => {
                        burger[key].style.background = "white"
                    });
                }
            }
            //black segunda seccion
            if (inview.is(positionSegundaSeccion) ){
                if(!this._isMounted) {
                    return
                }
                social = document.querySelectorAll(".menu-principal-link");
                Object.keys(social).forEach((key) => {
                    social[key].style.color = "black"
                });
                menu.classList.toggle("black",true);
                siguenos_container.style.color = "black";
                portafolio.style.color = "black";

                if(!inview.is(videoPosition)) {
                    if(!this._isMounted) {
                        return
                    }
                    Object.keys(burger).forEach((key)=>{
                        burger[key].style.background = "black"
                    });
                    this.setState({currentLogo: logoBlack});
                }
            }

            // white footer down
            if (inview.is(footerPosition)){
                social = document.querySelectorAll(".menu-principal-link");
                Object.keys(social).forEach((key) => {
                    social[key].style.color = "white"
                });
                menu.classList.toggle("black", false);
                siguenos_container.style.color = "white";
                portafolio.style.color = "white";

                if(!inview.is(positionSegundaSeccion)) {
                    this.setState({
                        currentLogo: logoWhite
                    });
                    Object.keys(burger).forEach((key) => {
                        burger[key].style.background = "white"
                    });
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section id="options" className="options">
                    <Container fluid>
                        <Row id="row1">
                            <Col md="12" id="logo-home" className="top-options">
                                <a href="/" className="logo-link">
                                    <img src={this.state.currentLogo} alt={this.state.currentLogo} className="logo-options" />
                                </a>
                                <div className="burger-container" onClick={this.handleClick}>
                                    <span className="burger-item"></span>
                                    <span className="burger-item"></span>
                                    <span className="burger-item"></span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="bottom-options">
                                <li className="menu-social-container color-white">
                                    <ul className="menu-social">
                                        {this.state.items.map(el => (
                                            <li className="menu-social-item" data-social={el.classes} key={el.ID}>
                                                <a href={el.url} target="_blank" rel="noopener noreferrer" className="link menu-principal-link">{el.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                    Síguenos
                                </li>
                                {
                                    url=== '/blog/' || url=== '/blog' ?
                                    <li id="filter" className="menu-filter-container menu-social-container color-white" >
                                        <ul className='menu-social'>
                                            {this.state.categories.map(el => (
                                                <li className="menu-social-item filter-item" onClick={e =>{ this.props.callback(e, el.id) }} data-social='filter-options-item' key={`filter-${el.id}`}>
                                                    <a href='https://google.com' rel="noopener noreferrer" className="link menu-principal-link">{el.name}</a>
                                                </li>
                                            ))}
                                        </ul> 
                                        Filtros
                                    </li>
                                    :
                                    <a href="/portafolio" className="portfolio-link color-white link">
                                        Portafolio
                                    </a>
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Opciones;