import React from 'react';
import { Container, Row } from 'reactstrap';
import './index.css';

const HeaderAbout = (props) => (
    <section id="featured-about">
        <Container fluid className="p-100">
            <Row className="header-title-container">
                <h1 className="title">
                    {props.tituloDestacado}
                    <span className="circle-title left"></span>
                    <span className="circle-title right"></span>
                </h1>
                <div className="title-line"></div>
            </Row>
        </Container>
    </section>
)

export default HeaderAbout;