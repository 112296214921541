import React from 'react';
import './index.css';
import { Container, Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';
import menu1 from '../../assets/images/1-menu.svg';
import menu2 from '../../assets/images/2-menu.svg';
import menu3 from '../../assets/images/3-menu.svg';
import menu4 from '../../assets/images/4-menu.svg';
import menu5 from '../../assets/images/5-menu.svg';

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            itemsMenu: [],
            showMenu: false,
            currentImage: 0,
            loading: true,
        }
        this.imgArray = [];
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let menuPrincipal = document.querySelector('#menu-principal-container');
        menuPrincipal.classList.remove('show-menu-principal');
        let body = document.body;
        body.removeAttribute('style');
        let imgSrc = [ menu1, menu2, menu3, menu4, menu5 ];
        let loaded = 0;
        imgSrc.forEach(iscr => {
          let nimg = new Image();
          nimg.src = iscr ;
          nimg.onload = ()=>{
            loaded++;
            if( loaded > 4 )
              this.setState({ loading: false });
          };
          this.imgArray.push(nimg);
        });
        fetch('http://oruga.orugadesarrollo.com/wp-json/menus/v1/menus/menu-1', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    itemsMenu: res.items
                });
                var menuItems = document.querySelectorAll('.link-menu-principal');
                menuItems.forEach((val, key) => {
                    val.classList.add('unable-menu-items');
                });
            }.bind(this))
            .catch(err => console.error(err));
    }

    changeImage = (image) => {
      this.setState({ currentImage: image });
     }

    hoverOn = (e, index) => {
        this.changeImage(index);
    }

    render() {
        return (
            <React.Fragment>
                <section id="menu-principal-container" className={`menu-principal-container ${this.state.showMenu ? 'show-menu-principal' : null}`}>
                    <Container fluid className="full-height">
                        <Row className="full-height">
                          { !this.state.loading &&
                              <Col xs="6" className="image-container" id="img-menu-container"  style={{ backgroundImage: "url('"+this.imgArray[this.state.currentImage].src+"')"}} />
                          }
                            <Col xs="6" className="menu-container">
                                <ul className="menu-principal">
                                    {this.state.itemsMenu.map((el, index) => (
                                        <li className="menu-principal-item color-white" key={el.ID} onMouseEnter={e => this.hoverOn(e, index)}>
                                            <span className="number-menu">0{index + 1}</span>
                                            <span className="linea-menu"></span>
                                            <a href={el.url} className="color-white link link-menu-principal">
                                                {el.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Menu;
