import React from 'react';
import Destacado from "../../components/Destacado";
import ContenidoPost from "../../components/ContenidoPost";
import VideoPost from "../../components/VideoPost";
import FooterBlog from "../../components/FooterBlog";
import Menu from "../../components/Menu";
import Opciones from "../../components/Opciones";
var url = window.location.pathname;


class IBlogs extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            videoDestacado : '',
            imagenDestacada: '', 
            títuloDestacado:'',
            textoCorto     :'',
	        backgroundImage: '',
	        blogVideo      : ''
        }
    }
    id = (url) => {
    	var res = url.substr(6)

    	return res
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidMount() {
    	this._isMounted = true;
	    fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/posts?slug='+url.slice(6))
	    .then(res => res.json())
	    .then(function(res) {
	    	console.log(res)
	        this._isMounted && this.setState({
	            imagenDestacada: res[0].acf.Imgnoticia,
	            tituloDestacado: res[0].title.rendered,
	            textoCorto:      res[0].acf.texto_corto,
	            blogVideo:       res[0].acf.seccion_video,
	            backgroundImage: res[0].acf.seccion_video_background,
	            content:         res[0].content.rendered
	        });
	    }.bind(this))
    }

	render(){
		return(
			<React.Fragment>
				<Opciones/>
				<Menu/>
				<Destacado
					imagenDestacada = {this.state.imagenDestacada}
					tituloDestacado = {this.state.tituloDestacado}
					textoCorto = {this.state.textoCorto}
				/>
				<ContenidoPost
					content = {this.state.content}
				/>
				<VideoPost
					blogVideo = {this.state.blogVideo}
					backgroundImage = {this.state.backgroundImage}
				/>
				<FooterBlog/>
			</React.Fragment>
		)
	}
}

export default IBlogs;