import React from 'react';
import './index.css';
import { Col, Button, Form, FormGroup, Input, Row, Container} from 'reactstrap';

class FooterBlog extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
            tituloFooterBlog: '',
            textoFooterBlog: '',
            email: '',
            description: '',
            countries: [],
            app: [],
            social: []
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    
    componentDidMount() {
        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/1606',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    tituloFooterBlog: res.acf.titulo_footer_blog,
                    textoFooterBlog : res.acf.texto_footer_blog
                });
            }.bind(this))
            .catch(err => console.error(err));

        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123',{headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                email: res.acf.correo,
                description: res.acf.descripcion,
                app: res.acf.app,
                social: res.acf.redes_sociales
            });
        }.bind(this))
        .catch(err => console.error(err));

    }
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let email = this.state.email;
    let auth = 'Basic ' + btoa('anystring:f48ec6909f991006d631f9dd4f4b267c-us20');
    fetch('https://us20.api.mailchimp.com/3.0/lists/df7b6a5f77/members/', {
        method: 'post',
        "headers": new Headers({
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Credentials":true,
          "Access-Control-Allow-Origin":true,
          "Authorization": auth
        }),
        body: {
          "email_address": email,
          "status": "subscribed"
        }
    })
    .then(res => res.json())
    .then(res => console.log(res))
    .catch(err => console.log(err));
  }

    render() {
        return (
            <React.Fragment>
                <footer id="foo" className='foo-blog'>
                    <Container>
                        <Row className="row-text">
                            <Col md="12" className="footer-email-container">
                                <h1>{this.state.tituloFooterBlog}</h1>
                            </Col>
                            <Col xs="9" className="p-blog color-white">
                                <p>{this.state.textoFooterBlog}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form className="newsletter-form" method="POST" onSubmit={this.handleSubmit}>
                                  <FormGroup className="row form-blog">
                                    <Col xs='12' sm='12' md='7' lg='7'>
                                        <Input className='input-blog' placeholder="Correo electrónico" onChange={this.handleChange} xs='9' name="emailInput" />
                                    </Col>
                                    <Col xs="5" sm='3' md='3' lg='2'>
                                        <Button value="ENVIAR" className='button-blog'>
                                            ENVIAR
                                        </Button>
                                    </Col>
                                  </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="app-container">
                                {this.state.app.map((el, index) => (
                                    <a href={el.hipervinculo} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <div className="social-container">
                                {this.state.social.map((el, index) => (
                                    <a href={el.link} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </div>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

export default FooterBlog;