import React, { Component } from 'react';
import Menu from '../../components/Menu';
import Opciones from '../../components/Opciones';
import PortafolioCarousel from '../../components/PortafolioCarousel';
import '../index.css';

class Portafolio extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            projects: [],
            categories: [],
            fetching:false,
            imagen:''
        }
    }

    componentWillUnmount() {
        this._isMounted=false;
    }

    componentDidMount() {
        this._isMounted=true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=4', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res){
                if(res.length !== 0){
                    this._isMounted && this.setState({
                        projects: res,
                        imagen: res.length > 0 ? res.acf : null,
                        status:res.status,
                        bgClass: 'backgroundColorBlue descriptionContainer',
                        bBotomClass: 'borderBottomColorBlue',
                        borderClass: 'borderColorBlue',
                    });
                }
                const selector = document.querySelector('.slick-current .visualPortafolio');
                    const url = selector.getAttribute('data-url');
                    console.log('componentDidMount '+url);
                    this.setState({
                    logo: url
                })
            }.bind(this))
            .catch(err => console.log(err));


        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/categories?exclude=1&order=desc', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    categories: res
                });
            }.bind(this))
            .catch(err => console.log(err));
    }
    handleClickWeb = () => {
        this.setState({ fetching: true });
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=4', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorBlue descriptionContainer',
                    bBotomClass: 'borderBottomColorBlue',
                    borderClass: 'borderColorBlue',
                    fetching: false,
                });
            }.bind(this))
            .catch(err => console.log(err));
    }

    handleClickMarketing = () => {
        this.setState({ fetching: true });
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=5', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorOrange descriptionContainer',
                    bBotomClass: 'borderBottomColorOrange',
                    borderClass: 'borderColorOrange',
                    fetching: false,
                });
            }.bind(this))
            .catch(err => console.log(err));
    }
    handleclickAudiovisual = () => {
        this.setState({ fetching: true });
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=6', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorPurple descriptionContainer',
                    bBotomClass: 'borderBottomColorPurple',
                    borderClass: 'borderColorPurple',
                    fetching: false,
                });
            }.bind(this))
            .catch(err => console.log(err));
    }
    handleclickBranding = () => {
        this.setState({ fetching: true });
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=7', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorGreen descriptionContainer',
                    bBotomClass: 'borderBottomColorGreen',
                    borderClass: 'borderColorGreen',
                    fetching: false,
                });
            }.bind(this))
            .catch(err => console.log(err));
    }

    render() {
        const { fetching } = this.state;
        return (
            <React.Fragment>
                <section id="visPortafolio">
                    <Opciones />
                    <Menu />
                    {fetching ? (<p>loaading...</p>) : (
                      <PortafolioCarousel 
                        clickWeb         ={this.handleClickWeb}
                        clickMarketing   ={this.handleClickMarketing}
                        clickAudiovisual ={this.handleclickAudiovisual}
                        clickBranding    ={this.handleclickBranding}
                        proyectos        ={this.state.projects}
                        categorias       ={this.state.categories} 
                        bgClass          ={this.state.bgClass}
                        bBotomClass      ={this.state.bBotomClass}
                        borderClass      ={this.state.borderClass}
                       />
                    )}
                </section>
            </React.Fragment>
        );
    }
}

export default Portafolio;
