import React from 'react';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import { Container, Row } from 'reactstrap';
import './index.css'

class VideoPost extends React.Component {
	constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
           backgroundImage: '',
           blogVideo: '',
           statusVideo: false
        }
    }

    handleClickVideo = () => {
        var player = document.getElementById('video-blog');
        var playPauseButton = document.querySelector('#play-pause');

        if (player.paused || player.ended) {
            player.play();
            this.setState({
                statusVideo: true
            });
            setTimeout(() => {
                playPauseButton.classList.add('hide-button');
            }, 800);
        } else {
            player.pause();
            playPauseButton.classList.remove('hide-button');
            this.setState({
                statusVideo: false
            });
        }
    }

    handleMouseMove = () => {
        var player = document.getElementById('video-blog');
        if (player.paused || player.ended) {
            let playPauseButton = document.querySelector('#play-pause');
            playPauseButton.classList.remove('hide-button');
        }else{
            let playPauseButton = document.querySelector('#play-pause');
            playPauseButton.classList.remove('hide-button');
            setTimeout(() => {
                playPauseButton.classList.add('hide-button');
            }, 800);
        }
    }

	render() {
        var a = this.props.blogVideo
		return (
			<React.Fragment>
            { a ?
				<Container fluid className='seccion-VideoPost' style={{backgroundImage: "url('"+this.props.backgroundImage+"')"}} >
					<Row>
						<div onMouseMove={this.handleMouseMove.bind(this)}> 
                            <ResponsiveEmbed className='embedBlog' aspect="a16by9">
                                <video className="modalVideo" id='video-blog' src={this.props.blogVideo}></video>
                            </ResponsiveEmbed>
                            <div onClick={this.handleClickVideo} data={console.log(this.props.statusVideo)} className={`play-pause ${this.state.statusVideo ? 'play' : 'pause'}`} id="play-pause">
                                <div className="play-button"></div>
                                <div className="pause-button"></div>
                                <div class="refresh-icon"></div>
                            </div>
                        </div>
					</Row>
				</Container>
                :
                ''
            }
			</React.Fragment>
		)
	}
}

export default VideoPost;