import React from 'react';
import { Row, Container } from 'reactstrap';
import Destacado from "../../components/Destacado";
import BlogItems from "../../components/BlogItems";
import FooterBlog from "../../components/FooterBlog";
import Menu from "../../components/Menu";
import Opciones from "../../components/Opciones";

class Blogs extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            videoDestacado: '',
            News: [],
            fetching: false
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount() {

        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123')
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    videoDestacado: res.acf.video_destacado
                });
                let video = document.querySelector('#featured-video');
                
                video && video.play();
            }.bind(this))

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/posts',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    News: res
                });
                var titleHeight = document.querySelectorAll('.tltBlog')
                this.equalHeight(titleHeight);
            }.bind(this)).catch(err => {
                console.error(err);
            });
        if(this._isMounted && this.setState){
        }
    }

    callback = (e, dataFromChild) => {
        this.setState({ fetching: true });
        e.preventDefault();
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/posts?categories='+dataFromChild,{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                News: res,
                fetching: false
            });
            var titleHeight = document.querySelectorAll('.tltBlog')
            this.equalHeight(titleHeight);
        }.bind(this)).catch(err => {
            console.error(err);
        })
    }

    equalHeight = (elements) =>{
        let maxHeight = 0;
        elements.forEach(function(element) {
            var currentHeight = element.offsetHeight;
            if(currentHeight > maxHeight) {
                maxHeight = currentHeight;
            }
        });
        this.asigHeight(elements, maxHeight);
    }
    asigHeight = (elements,value) => {
        elements.forEach(function(element) {
            element.style.height = value+'px';
        });
    }

    formatDate = (date) => {
        let d  = new Date(date)
        let fd = d.getDate() + '-' + (d.getMonth() + 1) + '-'+ d.getFullYear();
        return fd
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Destacado
                            condicional='si'
                            videoDestacado={this.state.videoDestacado}
                        />
                    </Row>
                    <Opciones
                        callback={this.callback}
                    />
                    <Row className='d-flex space-blog' id="blog-body" >
                        {this.state.fetching ? (<p>Loading...</p>) : (
                            this.state.News.map(New => (
                                <BlogItems
                                    lg    = '4'
                                    md    = '6'
                                    sm    = '12'
                                    xs    = '12'
                                    date  = {this.formatDate(New.date)}
                                    key   = {New.id}
                                    id    = {New.id}
                                    img   = {New.acf.Imgnoticia}
                                    title = {New.title.rendered}
                                    text  = {New.excerpt.rendered}
                                    showPost = {this.props.showPost}
                                    slug  = {New.slug}
                                />
                            ))
                        )}
                    </Row>
                    <Menu/>
                    <Row>
                        <FooterBlog/>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}


export default Blogs; 