import React, { Component } from "react";
import Menu from "../../components/Menu";
import PrimeraSeccionHome from "../../components/PrimeraSeccionHome";
import Destacado from "../../components/Destacado";
import Opciones from "../../components/Opciones";
import Video from "../../components/Video";
import Footer from "../../components/Footer";
import "../index.css";
import SegundaSeccionHome from "../../components/SegundaSeccion";
import { animateScroll as scroll} from 'react-scroll';
const scrollEvent = /Firefox/i.test(navigator.userAgent)
  ? "DOMMouseScroll"
  : "scroll";

  // const displaywheel = (delta) => {
  //   if(window.location.pathname === "/") {
  //     var posicionGlobal = window.scrollY;
  //     var servicios = document.getElementById("secServicios");
  //     var posicionServicios = servicios.offsetTop;
  //     if(delta < 0 && posicionGlobal > 0 && posicionGlobal < posicionServicios) { /* scroll down */
  //       setInterval(function() {
  //         while(window.scrollY <= posicionServicios) {
  //           window.scrollBy(0, 10);
  //         }
  //       }, 200);
  //     } else if (delta > 0 && posicionGlobal <= posicionServicios + 20) { /* scroll up */
  //       var upPos = 0;

  //       while (posicionGlobal > upPos) {
  //         window.scrollTo(0, upPos);
  //         upPos += 50;
  //       }
  //     }
  //   }
  // };
        
  class Home extends Component {
    constructor(props) {
      super(props);
      this._isMounted= false;
      this._isMounted2= false;
      this.state = {
            videoDestacado: ''
        }
      
    }

    scrollToTop() {
      scroll.scrollToTop({
        smooth: 'easeInOutQuart'
      });
    }
    componentWillUnmount() {      
      this._isMounted = false;
      this._isMounted2 = false;
    }
    componentDidMount() {      
      this._isMounted = true;
      this._isMounted2 = true;

      fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123')
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    videoDestacado: res.acf.video_destacado
                });
                let video = document.querySelector('#featured-video');
                
                video && video.play();
      }.bind(this))

     setTimeout(function(){
      
      if(!this._isMounted){
        return
      }
       if(this.props){
        var posicionGlobal = window.scrollY;
        var servicios = document.getElementById("secServicios");
        var posicionServicios = servicios.offsetTop;
        var burger=document.querySelectorAll(".burger-item");
        var siguenos_container=document.querySelector(".menu-social-container");
        var social=document.querySelectorAll(".menu-social-item a");
        var portafolio =document.querySelector(".portfolio-link");
        var menu = document.querySelector(".menu-social");
        var foo = document.getElementById("foo");
        var fooPos = foo.offsetTop;
        if(posicionGlobal>posicionServicios){
          menu.classList.add("black")
          siguenos_container.style.color="black"
          portafolio.style.color="black"
          Object.keys(burger).forEach((key)=>{
            burger[key].style.background="black"
          })
          Object.keys(social).forEach((key)=>{
            social[key].style.color="black"
          })
        }else{
          menu.classList.remove("black")
          siguenos_container.style.color="white"
          portafolio.style.color="white"
          Object.keys(burger).forEach((key)=>{
            burger[key].style.background="white"
          })
          Object.keys(social).forEach((key)=>{
            social[key].style.color="white"
          })
        }
        if(fooPos <= posicionGlobal){
          menu.classList.toggle("black",false)
          siguenos_container.style.color="white"
          portafolio.style.color="white"
          Object.keys(burger).forEach((key)=>{
            burger[key].style.background="white"
          })
          Object.keys(social).forEach((key)=>{
            social[key].style.color="white"
          });
        }
      }
      
      }.bind(this), 1000)
      if (window.location.pathname === '/') {
        var previusScroll = 0;
        document.addEventListener(scrollEvent, (e) => {
          var currentScroll = window.pageYOffset;
          if(currentScroll > previusScroll && this._isMounted) {
            // displaywheel(-1, e);
          } else if(this._isMounted) {
            // displaywheel(1, e);
          }
          previusScroll = currentScroll;
        }, false);
      }
    }

    render() {
      return (
        <React.Fragment>
          <Opciones />
          <Destacado 
            videoDestacado ={this.state.videoDestacado}
            condicional = 'si'
          />
          <Menu />
          <PrimeraSeccionHome />
          <Video />
          <SegundaSeccionHome />
          <Footer />
        </React.Fragment>
      );
    }
  }

export default Home;
