import React from 'react';
import './index.css';
import Testimonios from '../../components/Testimonios';
import Noticias from '../../components/noticias';
import Newsletter from '../../components/Newsletter';

class SegundaSeccionHome extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="segundaSeccion" className="segundaSeccion">
                    <Testimonios />
                    <Noticias />
                    <Newsletter />
                </section>
            </React.Fragment>
        );
    }
}

export default SegundaSeccionHome;