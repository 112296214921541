import React from 'react';
import './index.css';
import Cliente from '../Cliente';
import Titulos from '../Titulos';
import { Container, Row, Col } from 'reactstrap';
import inview from 'in-view';

class Clientes extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted= false;
        this.state = {
            post2: [],
            titulo: []
        }
    }

    componentWillUnmount(){
      this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted=true;
        inview('.divClientesTld').on('enter', () => {
            let titleService = document.querySelector('.divClientesTld .title-section-container');
            titleService.classList.add('Tlabel-width');
        });

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/titulos?menu_order=1',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    titulo: res[0]
                });
            }.bind(this))
            .catch(err => {
                console.error(err);
            });

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/cliente_oruga',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res){
                this._isMounted && this.setState({
                    post2: res
                });
            }.bind(this));

    }

    render() {
        return (
            <React.Fragment>
                <section id="secClientes" className="py-5">
                    <Container fluid className="p-100">
                        <Row>
                            <Col className="divClientesTld">
                                <Titulos
                                    tTitulo={this.state.titulo.title && this.state.titulo.status === "publish" ? this.state.titulo.title.rendered : " "}
                                    tContent={this.state.titulo.content && this.state.titulo.status === "publish" ? this.state.titulo.content.rendered : " "}
                                />
                            </Col>
                        </Row>
                        <Row className="divFatherContainerItem">
                            {this.state.post2.map(el => (
                                <Cliente
                                    key={el.id}
                                    styleCol={{ marginRight: 1 + '%', marginLeft: 1 + '%' }}
                                    xs={{ size: 6 }}
                                    sm={{ size: 4 }}
                                    md={{ size: 4, offset: 1 }}
                                    lg={{ size: 2 }}
                                    xl={{ size: 2 }}
                                    id={el.id}
                                    img={el.acf.imagenes_clientes}
                                />
                            ))}
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}



export default Clientes;