import React from 'react';
import './index.css';
import { Col } from 'reactstrap';

class Noticia extends React.Component {

	render() {
		return (

			<React.Fragment>
				<Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} key={this.props.id} className="pDiv">
					<a href={`blog/${this.props.slug}`}>
						<img className="imgN" src={this.props.img} alt={this.props.img} />
						<p className="parN" >{this.props.text}</p>
					</a>
				</Col>
			</React.Fragment>
		);
	}
}

export default Noticia;