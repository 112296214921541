import React from 'react';
import './index.css';
import { Container, Row, Col } from 'reactstrap';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
            email: '',
            description: '',
            countries: [],
            app: [],
            social: []
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123',{headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                email: res.acf.correo,
                description: res.acf.descripcion,
                app: res.acf.app,
                social: res.acf.redes_sociales
            });
        }.bind(this))
        .catch(err => console.error(err));

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pais?order=asc',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    countries: res
                });
            }.bind(this))
            .catch(err => console.error(err));
    }

    render() {
        return (
            <React.Fragment>
                <footer id="foo">
                    <Container>
                        <Row className="row-text">
                            <Col md="12" className="footer-email-container">
                                <a href={`mailto: ${this.state.email}`} rel="noopener noreferrer" className="email-footer color-white link">{this.state.email}</a>
                            </Col>
                            <Col xs="9" className="footer-description-container color-white">
                                <p>{this.state.description}</p>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.countries.map((el, index) => (
                                <Col xs="12" sm="6" md="3" lg="3" key={index} className="country-container">
                                    <img src={el.acf.bandera} alt={el.acf.bandera} />
                                    <h3 className="color-white">{el.title.rendered}</h3>
                                    <p className="color-white">{el.acf.telefono}</p>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col xs="12" className="contact-link-container">
                                <a href="/" className="color-white link">Contáctanos</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="app-container">
                                {this.state.app.map((el, index) => (
                                    <a href={el.hipervinculo} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <div className="social-container">
                                {this.state.social.map((el, index) => (
                                    <a href={el.link} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </div>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;