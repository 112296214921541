import React from 'react';
import './index.css';
import Titulos from '../Titulos';
import Slider from "react-slick";
import ItemCarousel from '../itemCarousel'
import { Container, Row, Col } from 'reactstrap';
import inview from 'in-view';

class Testimonios extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted=false;
		this.state = {
			testimonio: [],
			titulo: []
		}
	}
	componentWillUnmount() {
		this._isMounted=false;
	}
	componentDidMount() {
		this._isMounted=true;
        inview('.divTestimoniosTld').on('enter', () => {
			let titleService = document.querySelector('.divTestimoniosTld .title-section-container');
			titleService.classList.add('Tlabel-width');
		});
        
	    fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/titulos?menu_order=3',{headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
	    .then(res => res.json())
	    .then(function(res) {
	        this._isMounted && this.setState({
	            titulo: res[0]
	        });
	    }.bind(this))
	    .catch(err=>{
	        console.error(err);
		});
	    
		fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/testimonio',{headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
			.then(res => res.json())
			.then(function(res) {
				this._isMounted && this.setState({
					testimonio: res
				});
				Change();
			}.bind(this)).catch(err=>{
	        console.error(err);
		});

		const Change = () => {
			var testimonio = document.getElementsByClassName('slick-slide');
			var marco= document.getElementsByClassName('imgContentT');
			var mutationObserver = new MutationObserver(function(mutations) {
				if(mutations) {
					Object.keys(marco).forEach((key) => {
						var elem = marco[key];
						var pos = 0;
						var id = setInterval(frame, 1);
						function frame() {
							if (pos === 90) {
								clearInterval(id);
							} else {
								pos++; 
								elem.style.transform ="rotate("+ pos + "deg)"; 
							}
						}
					});
				}
			});

			mutationObserver.observe(testimonio[0], {
				attributes: true,
				attributeFilter: [ 'class']
			});
		}
	}

	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 60,
			slidesToShow: 1,
			initialSlide: 0,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			autoplaySpeed: 3000,
			autoplay: true,
			pauseOnHover: false
		};

		return (
			<React.Fragment>
				<section id="secTestimonios" className="py-5">
					<Container fluid className="p-100">
						<Row>
							<Col className="divTestimoniosTld">
								<Titulos
									tTitulo={this.state.titulo.title && this.state.titulo.status === "publish" ? this.state.titulo.title.rendered : " "}
									tContent={this.state.titulo.content && this.state.titulo.status === "publish" ? this.state.titulo.content.rendered : " "}
								/>
							</Col>
						</Row>
						<Slider {...settings} className='divFatherContainerItem pt-5'>
						
							{this.state.testimonio.map(el => (
								<ItemCarousel
									key={el.id}
									tituloContenido={el.title.rendered}
									Contenido={el.content.rendered}
									Imagen={el.acf.imagen_testimonios}
									Cliente_testimonio={el.acf.cliente_testimonios}
								/>
							))}
						</Slider>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Testimonios;