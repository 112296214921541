import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import './index.css'
var a;

class contenidoPost extends React.Component {

	render(){
		a = { __html: this.props.content }
		return(
			<React.Fragment>
				<Container fluid className='seccion-contenido'>
					<Row>
						<Col lg='12' dangerouslySetInnerHTML= {a !== undefined ? a : ""} />
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}
export default contenidoPost;