import React from 'react';

const MemberItem = (props) => (
    <div className="memberItem">
        <div style={{backgroundImage: `url(${props.photoMember})`}} className="photoMember">
            <div className="info-member" style={{boxShadow: `0px -20px 53px ${props.color}`}}>
                <h3 style={{background: props.color, borderBottom: `2px solid ${props.color}`}}>{props.fullname}</h3>
            </div>
        </div>
        <div className="line-photo" style={{background: props.color}}></div>
        <p className="cargo">{props.cargo}</p>
        <div className="social-items">
            {props.redesSociales.map((element, index) => (
                <a href={element.link_red} key={index} target="_blank" rel="noopener noreferrer">
                    <img src={element.logo_red} alt={element.nombre_red} />
                </a>
            ))}
        </div>
    </div>
)

export default MemberItem;