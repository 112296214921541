import React from 'react';
import './index.css';
import { Col, Button, Form, FormGroup,  Input} from 'reactstrap';


class NewsletterForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let email = this.state.email;
    let auth = 'Basic ' + btoa('anystring:f48ec6909f991006d631f9dd4f4b267c-us20');
    fetch('https://us20.api.mailchimp.com/3.0/lists/df7b6a5f77/members/', {
        method: 'post',
        "headers": new Headers({
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Credentials":true,
          "Access-Control-Allow-Origin":true,
          "Authorization": auth
        }),
        body: {
          "email_address": email,
          "status": "subscribed"
        }
    })
    .then(res => res.json())
    .then(res => console.log(res))
    .catch(err => console.log(err));
  }

  render() {
    return (
      <Col xs="12">
        <Form className="newsletter-form" method="POST" onSubmit={this.handleSubmit}>
          <FormGroup className="row">
            <Col xs='12' sm='12' md='7' lg='7'>
                <Input placeholder="Correo electrónico" onChange={this.handleChange} value={this.state.email} xs='9' name="emailInput" />
            </Col>
            <Col xs="5" sm='3' md='3' lg='2'>
              <Button value="ENVIAR">
                ENVIAR
                </Button>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    )
  }
}


export default NewsletterForm;