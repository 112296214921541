import React, { Component } from 'react';
import './index.css';
import NewsletterForm from '../NewsletterForm'
import { Container, Row, Col } from 'reactstrap';


class Newsletter extends Component {

    constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
            textNewsletter: ''
        }
    }
    componentWillUnmount(){
        this._isMounted =false;
    }
    componentDidMount() {
        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123')
            .then(res => res.json())
            .then(function(res){
                this._isMounted && this.setState({
                    textNewsletter: res.acf.newsletter
                });
            }.bind(this))
    }

    render() {
        return (
            <React.Fragment>
                <section id="Newsletter" className="py-5">
                    <Container fluid className="p-100">
                        <Row>
                            <Col xs="12" className="tltNewsletter">
                                <h2>{this.state.textNewsletter}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <NewsletterForm
                                lg='3'
                                md='12'
                                sm='12'
                                xs='12'
                                lgi='3'
                                mdi='12'
                                smi='12'
                                xsi='12' />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }

}
export default Newsletter;