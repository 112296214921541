import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { Container, Row, Col, Button } from 'reactstrap';
import './index.css';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import MemberItem from '../MemberItem';

class TeamOruga extends React.Component {
    render() {
        const options = {
            autoplay: true,
            center: true,
            loop: true,
            dots: false,
            nav: false,
            autoplayTimeout: 700000,
            responsive: {
                991: {
                    items: 3
                },
                768: {
                    items: 2
                },
                320: {
                    items: 1
                }
            }
        }
        return (
            <section id="teamWork">
                <Container>
                    <Row>
                        <Col xs="12">
                            <div className="title-team">
                                <Button
                                    className="left-team btn-teamSlider"
                                    onClick={() => this.refs.teamSlider.prev()}
                                    style={{background: `url(${arrowLeft})`}}
                                >
                                </Button>
                                <div className="line-title left"></div>
                                <h2>Team Oruga</h2>
                                <div className="line-title right"></div>
                                <Button
                                    className="right-team btn-teamSlider"
                                    onClick={() => this.refs.teamSlider.next()}
                                    style={{background: `url(${arrowRight})`}}
                                >
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12">
                            <OwlCarousel ref="teamSlider" options={options}>
                                {this.props.equipo.map((el, index) => (
                                    <MemberItem 
                                        key={index}
                                        index={index}
                                        fullname={el.nombre_completo}
                                        cargo={el.cargo}
                                        photoMember={el.foto_member}
                                        color={el.color}
                                        redesSociales={el.redes_sociales}
                                    />
                                ))}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default TeamOruga;
