import React from 'react';
import './index.css';
var a;

class Destacado extends React.Component {
    
    constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
            videoDestacado: '',
            imagenDestacada: '',
            condicional:'',
            tituloDestacado:'',
            textoCorto:''
        }
    }
    componentWillUnmount(){
        this._isMounted =false;
    }


    render() {
        a = this.props.condicional
        return (
            <React.Fragment>
                <section className="featured" id="featured">
                { a === 'si' ?
                    <video className="featured-video" id="featured-video" autoPlay muted src={this.props.videoDestacado}></video>
                    :
                    <div className='featured-img-father'>
                        <div className="featured-img" style={{backgroundImage: "url('"+this.props.imagenDestacada+"')"}}></div>
                        <div className='shade'></div>
                        <div className='featured-title' data-text={this.props.tituloDestacado}>{this.props.tituloDestacado}</div>
                        <div className='featured-texto-corto'>{this.props.textoCorto}</div>
                    </div>
                }
                </section>
                {/* <section ref={(section) => { this.Section = section; }}></section> */}
            </React.Fragment>
        );
    }
}

export default Destacado;