import React, { Component } from "react";
import "./index.css";
import Titulos from '../Titulos';
import Noticia from '../Noticia';
import { Container, Row, Col } from 'reactstrap';
import inview from 'in-view';
import  WOW from "wowjs";

class Noticias extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            News: [],
            titulo: []

        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        if (typeof window !== 'undefined') {
            const wow = new WOW.WOW({
              live: false,
            })
            wow.init()
          }
        inview('.divNoticiasTld').on('enter', () => {
            let titleService = document.querySelector('.divNoticiasTld .title-section-container');
            titleService.classList.add('Tlabel-width');
        });

        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/titulos?orderby=date&order=asc&menu_order=4',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    titulo: res[0]
                });
            }.bind(this))
            .catch(err => {
                console.error(err);
            });


        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/posts?per_page=3',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    News: res
                });
            }.bind(this)).catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <React.Fragment>
                <section id="noticias" className="py-5">
                    <Container fluid className="p-100">
                        <Row>
                            <Col className="divNoticiasTld">
                                <Titulos
                                    tTitulo={this.state.titulo.title && this.state.titulo.status === "publish" ? this.state.titulo.title.rendered : " "}
                                    tContent={this.state.titulo.content && this.state.titulo.status === "publish" ? this.state.titulo.content.rendered : " "}
                                />
                            </Col>
                        </Row>
                        <Row className="divFatherContainerItem wow fadeInLeft" data-wow-delay="0.3s">
                            {this.state.News && this.state.News.map(New => (
                                <Noticia
                                    xl='3'
                                    lg='3'
                                    md='12'
                                    sm='12'
                                    xs='12'
                                    key={New.id}
                                    id={New.id}
                                    img={New.acf.Imgnoticia}
                                    text={New.title.rendered}
                                    slug={New.slug}
                                />
                            ))}
                        </Row>
                        <Row className="divLinkBlogContainer">
                            <Col>
                                <a href="/blog" className="aLinkBlogContainer color-dark-gray link">Visita nuestro blog</a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }

}
export default Noticias;