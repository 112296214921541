import React, { Component } from "react";
import "./index.css";
import { Row, Col } from 'reactstrap';
var a, b;
class Titulos extends Component {


    render() {
        a = { __html: this.props.tContent }
        b = { __html: this.props.tTitulo }
        return (
            <React.Fragment>
                <Row>
                    <Col  className="Tlabel" xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg}>
                        <div className="title-section-container"></div>
                        <h2 dangerouslySetInnerHTML={b !== undefined ? b : ""}></h2>
                    </Col>
                </Row>
                <Row >
                    <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} className="Tcontent" dangerouslySetInnerHTML={a !== undefined ? a : ""} >

                    </Col>
                </Row>
            </React.Fragment>
        )
    }

}
export default Titulos;