import React from 'react';
import Opciones from '../../components/Opciones';
import Menu from '../../components/Menu';
import HeaderAbout from '../../components/HeaderAbout';
import Procesos from '../../components/Procesos';
import Estadisticas from '../../components/Estadisticas';
import TeamOruga from '../../components/TeamOruga';
import Footer from '../../components/Footer';
import inview from 'in-view';
import './index.css';

class About extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            tituloDestacado: '',
            tituloProceso: '',
            contenidoProceso: '',
            procesos: [],
            tituloEstadistica: '',
            contenidoEstadistica: '',
            estadisticas: [],
            equipo: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        fetch('http://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/1962')
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    tituloDestacado: res.acf.titulo_destacado,
                    tituloProceso: res.acf.titulo_procesos,
                    contenidoProceso: res.acf.contenido_procesos,
                    procesos: res.acf.procesos,
                    tituloEstadistica: res.acf.titulo_estadisticas,
                    contenidoEstadistica: res.acf.contenido_estadisticas,
                    estadisticas: res.acf.estadisticas,
                    equipo: res.acf.team_oruga
                });
                inview('#procesos .Tlabel').on('enter', () => {
                    let titleService = document.querySelector('#procesos .Tlabel .title-section-container');
                    titleService.classList.add('Tlabel-width');
                });
                inview('#estadisticas .Tlabel').on('enter', () => {
                    let titleService = document.querySelector('#estadisticas .Tlabel .title-section-container');
                    titleService.classList.add('Tlabel-width');
                });
            }.bind(this))
    }

    render() {
        return (
            <React.Fragment>
                <Opciones />
                <Menu />
                <HeaderAbout 
                    tituloDestacado={this.state.tituloDestacado}
                />
                <div className="light-background">
                    <Procesos 
                        tituloProceso={this.state.tituloProceso}
                        contenidoProceso={this.state.contenidoProceso}
                        procesos={this.state.procesos}
                    />
                    <Estadisticas 
                        tituloEstadistica={this.state.tituloEstadistica}
                        contenidoEstadistica={this.state.contenidoEstadistica}
                        estadisticas={this.state.estadisticas}
                    />
                </div>
                <TeamOruga 
                    equipo={this.state.equipo}
                />
                <Footer />
            </React.Fragment>
        )
    }
}

export default About;